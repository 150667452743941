export const apiRoutes = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    me: 'v1/users/me',
  },
  users: {
    allUsers: '/v1/users',
    createUser: '/v1/users/',
    updateUser: id => `/v1/users/${id}`,
    deleteUser: id => `/v1/users/${id}`,
    changePassword: id => `/v1/users/${id}/password`,
  },
  socialCenter: {
    me: '/users/me',
    activeUserAttentions: '/attention?closed=false',
    closedUserAttentions: (sinceTimestamp, untilTimestamp) => `/attention?closed=true&since_timestamp=${sinceTimestamp}&ultil_timestamp=${untilTimestamp}`,
    allUserAttentions: '/attention',
    createNote: '/notes',
    listNoteByAttention: attentionId => `/notes/attention/${attentionId}`,
    removeNote: noteId => `/notes/${noteId}`,
    closeAttention: (attentionId, userId) => `/attention/${attentionId}/end?supervisorId=${userId}`,
    singleAttention: chatId => `attention/${chatId}`,
    attentionMessages: attentionId => `/attention/${attentionId}`,
    getNamesChannelCampaign: attentionId => `/attention/${attentionId}?channel=true&campaign=true`,
    messagesAttentions: (attentionId, order, start, limit) => `/messages/${attentionId}/${order}/${start}/${limit}`,
    sendComment: (commentId, attentionId, message) => `/messages/comment?commentId=${commentId}&&attentionId=${attentionId}&&message=${message}`,
    sendMessageAttention: (messageType = 'MESSAGE_TAG', replayTo = 'INBOX', idToSend) => `/messages/send?messageType=${messageType}&&replayTo=${replayTo}&&idToSend=${idToSend}`,
    sendMessageAttentionWithAttachments: (messageType = 'MESSAGE_TAG', replayTo = 'INBOX', idToSend) =>
      `/messages/send-attachment?messageType=${messageType}&&replayTo=${replayTo}&&idToSend=${idToSend}`,
    chatReport: accountId => `/attention/account/${accountId}?`,

    chatReportStatusClose: (accountId, status) => `/attention/account/${accountId}?closed=${status}`,
    chatReportStatusCloseCount: (accountId, userId, since, until) => `/attention/account/${accountId}/agent/${userId}?until_timestamp=${until}`,
    chatReportAtenttionByAgent: (agentId, status) => `/attention/agent/${agentId}?closed=${status}`,

    chatReportSinceTimestamp: (accountId, time) => `/attention/account/${accountId}?since_timestamp=${time}`,

    changeAgentStatus: (userId, state) => `/users/status/agent/${userId}/${state}`, // 'inactive', 'active', 'paused'
    transferAttention: (attentionId, agentId, userId, newUserId) => `/attention/${attentionId}/change_user/${agentId}/${newUserId}`,
    saveShortcuts: `/shortcuts`,
    deleteShortcut: shortcut => `/shortcuts/${shortcut}`,
    updateShortcut: shortcut => `/shortcuts/${shortcut}`,
    getShortcutsByCampaing: campaign => `/shortcuts/${campaign}`,
    getCampaignByAccount: accountId => `/campaign/account/${accountId}`,
    getCampaignByUser: profile => `/campaign?profile=${profile}`,
    getChannel: '/channel',
    channel: accountId => `/channel/account/${accountId}`,
    getChatHistory: (entityId, providerId, entityTypeCode = 'CONVERSATION') => `/issue-transition?entityId=${entityId}&providerId=${providerId}&entityTypeCode=${entityTypeCode}`,
    lastChats: (accountId, providerId) => `/attention/account/${accountId}?closed=true&client_provider_id=${providerId}`,
    userByCampaign: campaignId => `/users/campaign/${campaignId}`,
    searchText: (text, start, end) => `/messages/text?findText=${text}&order=asc&since_timestamp=${start}&ultil_timestamp=${end}`,
    createTag: `/tags`,
    updateTag: id => `/tags/${id}`,
    deleteTag: id => `/tags/${id}`,
    getTagsByCampaign: campaignId => `/tags/campaign/${campaignId}`,
    addTagByAttention: attentionId => `/attention/${attentionId}/tag`,
    removeTagByAttention: `/attention/remove_tag`,
    getAverageTime: (dateFrom, dateTo) =>
      dateFrom && dateTo
        ? `/attention/average-time?dateFrom=${dateFrom}&dateTo=${dateTo}`
        : dateFrom
        ? `/attention/average-time?dateFrom=${dateFrom}`
        : dateTo
        ? `/attention/average-time?dateTo=${dateTo}`
        : `/attention/average-time`,
    getAverageTimeByCampaign: (campaignId, dateFrom, dateTo) => `/attention/average-time?campaignId=${campaignId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    getAverageTimeByCampaignChannel: (campaignId, dateFrom, dateTo, channelId) => `/attention/average-time?campaignId=${campaignId}&dateFrom=${dateFrom}&dateTo=${dateTo}&channelId=${channelId}`,
    getMetaMessageTemplates: phoneNumberId => `/whatsapp/messages/meta/template?phoneNumberId=${phoneNumberId}`,
    createSendTest: '/whatsapp/messages/meta/template',
    createSendExcel: '/whatsapp/messages/meta/template-massive',
    getListExcelClients: '/messages/list-excel-clients',
    getListExcelParams: path => `/messages/excel-client-params?path=${path}`,
    // getMetaMessageTemplates: phoneNumberId => `/whatsapp/messages/meta/template?phoneNumberId=${phoneNumberId}`,

    sendExcel: '/messages/send-excel-clients',
  },
  omnicanal: {
    getAgents: accountId => `agents/account/${accountId}`,
  },
  email: {
    loadEmails: 'emails/load',
    casesList: 'cases/list',
    emailList: 'emails/list',
    sendEmail: 'emails/send',
    assignCase: 'cases/change-status',
    caseHistory: 'case/history',
    caseHistoryId: id => `case/history/${id}`,
    authEmailGmail: 'emails/permissions',
    caseReport: 'cases/report',
    providers: 'inboxes/list',
    inboxSave: 'inbox/save',
    inboxUpdate: 'inbox/update',
    noteList: 'notes/list',
    saveNote: 'note/save',
    updateCase: 'case/update',
    uploadFiles: 'files/load',
    templates: 'templates/list',
    saveTemplate: 'template/save',
    testLoadData: 'emails/testload',
    testSendData: 'emails/testsend',
  },
  report: {
    getReportAll: (accountId, userId, campaignId, dateStart, dateEnd) =>
      `user-event/type/LOGIN?account=${accountId}&userId=${userId}&campaignId=${campaignId}&betweenStartAt=${dateStart} 00:00:00,${dateEnd} 23:59:59`,
    getReportTmo: (campaignId, channelId, dateStart, dateEnd) => `/attention/average-time?campaignId=${campaignId}&channelId=${channelId}&dateFrom=${dateStart}&dateTo=${dateEnd}`,
    reportTags: accountId => `attention-event?account=${accountId}&`,
  },
  fullfillment: {
    workFlow: 'workFlow',
    accountWorkFlow: 'account-workFlow',
    steps: 'step',
    issue: 'issue',
    transition: 'transition',
    issueTransition: 'issue-transition',
  },
};

export const firebaseNodes = {
  whatsapp: {
    attentions: 'attentions',
    attentionId: attentionId => `attentions/${attentionId}`,
  },
  email: {
    cases: 'cases',
  },
  users: {
    state: 'users-state',
  },
  reports: {
    report: (accountId, yearCurrent, monthCurrent, dayCurrent) => `reports/accounts/${accountId}/history/year/${yearCurrent}/month/${monthCurrent}/date/${dayCurrent}/attentions/closed`,
    reportopen: accountId => `reports/accounts/${accountId}/attentions/open`,
    reportByCampaignClosed: (accountId, campaingSelect, yearCurrent, monthCurrent, dayCurrent) =>
      `reports/accounts/${accountId}/campaigns/${campaingSelect}/history/year/2022/month/6/date/28/attentions/closed`,
    reportByCampaignOpen: (accountId, campaingSelect) => `reports/accounts/${accountId}/campaigns/${campaingSelect}/attentions/open`,
    reportByCampaignByChannelOpen: (accountId, campaingSelect, channelSelect) => `reports/accounts/${accountId}/campaigns/${campaingSelect}/channels/${channelSelect}/attentions/open`,
    reportByCampaignByChannelClosed: (accountId, campaingSelect, channelSelect, yearCurrent, monthCurrent, dayCurrent) =>
      `reports/accounts/${accountId}/campaigns/${campaingSelect}/channels/${channelSelect}/history/year/${yearCurrent}/month/${monthCurrent}/date/${dayCurrent}/attentions/closed`,
    usersConnect: accountId => `reports/accounts/${accountId}/users`,
  },
};
